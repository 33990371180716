import WebIM from "easemob-websdk"
import config from "./WebIMConfig.js"
import emoji from "./emoji.js"
let conn = {};
WebIM.config = config;
WebIM.emoji = emoji
conn = WebIM.conn = new WebIM.connection({
		appKey: WebIM.config.appkey,
		isHttpDNS: WebIM.config.isHttpDNS,
		isMultiLoginSessions: WebIM.config.isMultiLoginSessions,
		https: WebIM.config.https,
		url: WebIM.config.socketServer,
		apiUrl: WebIM.config.restServer,
		isAutoLogin: WebIM.config.isAutoLogin,
		autoReconnectNumMax: WebIM.config.autoReconnectNumMax,
		autoReconnectInterval: WebIM.config.autoReconnectInterval,
		delivery: WebIM.config.delivery,
		useOwnUploadFun: WebIM.config.useOwnUploadFun
})
let IM = {
	register: (data)=>{ //注册
		let config = {
			username: 'free_company_'+data.id,
			password: '123456',
			nickname: '',
			appKey: WebIM.config.appkey,
			success:data.success,
			error:data.error
		}
		conn.registerUser(config);
	},
	login: (data)=>{ //登录
		let config = {
			user: 'free_company_'+data.id,
			pwd: '123456',
			appKey: WebIM.config.appkey,
			error:data.error,
			success:data.success
		}
		conn.open(config);
	},
	outLogin: ()=>{ //退出登录
		conn.close();
	},
	getList: ()=>{ //获取对话列表
		return conn.getSessionList()
	},
	getContent: (data)=>{ //获取对话能容
		let config = {
			queue: data.id,
			isGroup: false,
			count: 20,
			success: data.success,
		}
		conn.fetchHistoryMessages(config)
	},
	refreshContentCatch: ()=>{  // 清除游标缓存
		conn.mr_cache = [];
	},
	read: (data)=>{ //已读
		var msg = new WebIM.message('channel',conn.getUniqueId());
		msg.set({
			to: data.id
		});
		conn.send(msg.body);
	},
	// 发送消息
	send: (data)=>{
		if(!data.type) data.type = 'txt';
		// console.log("before send mes, data = ", data);
		let ext = {									 // //扩展消息
			chat_attribute_address_detail:"",
			chat_attribute_type:data.type,
			chat_attribute_avatar_from:data.chat_attribute_avatar_from,
			chat_attribute_avatar_to:data.chat_attribute_avatar_to,
			chat_attribute_company_id:data.chat_attribute_company_id,
			chat_attribute_gender_from:data.chat_attribute_gender_from,
			chat_attribute_gender_to:data.chat_attribute_gender_to,
			chat_attribute_job_intention_id:data.chat_attribute_job_intention_id,
			chat_attribute_nickname_from:data.chat_attribute_nickname_from,
			chat_attribute_nickname_to:data.chat_attribute_nickname_to,
			chat_attribute_position_id:data.chat_attribute_position_id,
			chat_attribute_position_name:data.chat_attribute_position_name,
			chat_attribute_handStatus:data.chat_attribute_handStatus,
			chat_attribute_companyUserId:data.chat_attribute_companyUserId,
			chat_attribute_userId:data.chat_attribute_userId,
		};
		let option = {};
		if(data.type && data.type == 'txt'){
			option = {
				msg: data.content,                  // 消息内容
				to: 'free_user_'+data.id,           // 接收消息对象（用户id）
				chatType: 'singleChat',                  // 设置为单聊
				type: 'txt',		  // 消息类型: 'txt':文本消息,'loc':位置消息,'custom':自定义消息,'img':图片消息,'cmd':命令消息
				ext: ext,
				success: data.success,
				fail: function(e){
					// 失败原因:
					// e.type === '603' 被禁言
					// e.type === '605' 群组不存在
					// e.type === '602' 不在群组或聊天室中
					// e.type === '504' 撤回消息时超出撤回时间
					// e.type === '505' 未开通消息撤回
					// e.type === '506' 没有在群组或聊天室白名单
					// e.type === '501' 消息包含敏感词
					// e.type === '502' 被设置的自定义拦截捕获
					// e.type === '503' 未知错误
					console.log(e,"Send private text error");
				},
			}
		} else if(data.type === 'img'){
			const url = data.content.url;
			option = {
				chatType: 'singleChat',
				to: 'free_user_'+data.id,
				msg: data.content.url,
				ext: ext,
				width: data.content.width,
				height: data.content.height,
				type: 'img',
				// url:
				url,
				// success: data.success,
				// fail: function(e){
				// 	console.log(e,"Send private text error");
				// },
			}
		} else if(data.type === 'custom'){
			option = {
				customEvent: data.content,                  // 消息内容
				to: 'free_user_'+data.id,           // 接收消息对象（用户id）
				chatType: 'singleChat',                  // 设置为单聊
				type: 'custom',		  // 消息类型: 'txt':文本消息,'loc':位置消息,'custom':自定义消息,'img':图片消息,'cmd':命令消息
				ext: ext,
				success: data.success,
				params: {},
				customExts: {},
				fail: function(e){
					console.log(e,"Send private text error");
				},
			}
		} else if(data.type === 'loc'){
			ext.chat_attribute_address_detail = data.content.addDetail;
			option = {
				to: 'free_user_'+data.id,           // 接收消息对象（用户id）
				chatType: 'singleChat',                  // 设置为单聊
				type: 'loc',		  // 消息类型: 'txt':文本消息,'loc':位置消息,'custom':自定义消息,'img':图片消息,'cmd':命令消息
				buildingName:"",
				addr: data.content.addName,
				lat: data.content.lat,
				lng: data.content.lng,
				ext: ext,
				success: data.success,
				fail: function(e){
					console.log(e,"Send private text error");
				},
			}
		} else {
			option = {
				msg: data.content,                  // 消息内容
				to: 'free_user_'+data.id,           // 接收消息对象（用户id）
				chatType: 'singleChat',                  // 设置为单聊
				type: 'txt',		  // 消息类型: 'txt':文本消息,'loc':位置消息,'custom':自定义消息,'img':图片消息,'cmd':命令消息
				ext: ext,
				success: data.success,
				fail: function(e){
					console.log(e,"Send private text error");
				},
			}
		}
		if(option === {}){
			console.log("Send error");
			return;
		}
		if(data.type==='img'){
			// debugger
			let msg = WebIM.message.create(option);
			conn.send(msg).then(() => {
					console.log('success!'); // 消息发送成功。
					data.success();
				})
				.catch((e) => {
					console.log('fail', e); // 如禁言或拉黑后消息发送失败。
				});
			return;

		}
		let id = conn.getUniqueId();                 // 生成本地消息id
		// let msg = new WebIM.message(data.type, id);      // 创建文本消息
		// let msg = WebIM.message.create(option);
		let params = {
			type: data.type,
			id: id,
		};
		let msg = WebIM.message.createOldMsg(params);
		// debugger
		// console.log(">>>>>> type=", data.type, ", config = ", option);
		msg.set(option);
		conn.send(msg.body);
			// .then(() => {
			// 	console.log('Success'); // 消息发送成功。
			// }).catch((e) => {
			// 	console.log('Fail', e); // 如禁言或拉黑后消息发送失败。
			// });
	},
	onTextMessage:(callback)=>{
		// console.log("WebIM.IM ----onTextMessage--------");
		// conn.addEventHandler("TextMessage", {
		// 	onTextMessage: (msg) => {
		// 		console.log('>>>收到TextMessage-------', msg);
		// 		callback(msg);
		// 	}, // 收到文本消息。
		// })
		conn.listen({
			onTextMessage:(message)=>{
				callback(message)
			}
		})
	},
	onRecallMessage:(callback)=>{
		console.log(">>>>WebIM.IM addEventHandler.onRecallMessage----");
		conn.addEventHandler("onRecallMessage", {
			onRecallMessage:( message )=>{
				callback(message)
			},
		})
	},
	onImageMessage: (callback)=>{
		console.log(">>>>WebIM.IM addEventHandler.onImageMessage----");
		conn.addEventHandler("onImageMessage", {
			onImageMessage:( message )=>{
				callback(message)
			},
		})
	},
	onLocationMessage: (callback)=>{
		console.log(">>>WebIM.IM addEventHandler.onLocationMessage----");
		conn.addEventHandler("onLocationMessage", {
			onLocationMessage:( message )=>{
				callback(message)
			},
		})
	},
	onCustomMessage: (callback)=>{
		console.log(">>>WebIM.IM addEventHandler.onCustomMessage----");
		conn.addEventHandler("onCustomMessage",{
			onCustomMessage:( message )=>{
				callback(message)
			},
		})
	},
	onReceivedMessage:(callback)=>{
		console.log("WebIM.IM ----addEventHandler.onReceivedMessage--------");
		conn.addEventHandler("onReceivedMessage", {
			onReceivedMessage:( message )=>{
				callback(message)
			},
		})
	},
	onChannelMessage:(callback)=>{
		console.log("WebIM.IM ----addEventHandler.onChannelMessage--------");
		conn.addEventHandler("onCustomMessage", {
			onChannelMessage:(message)=>{
				// console.log("WebIM.IM ----onChannelMessage--------", message);
				callback(message)
			}
		})
	},
	removeEventHandlerAll(id){
		// if(id==='all' || id === 'messageListen') conn.removeEventHandler("messageListen");
		if(id==='all' || id === 'onImageMessage') conn.removeEventHandler("onImageMessage");
		if(id==='all' || id === 'onLocationMessage') conn.removeEventHandler("onLocationMessage");
		if(id==='all' || id === 'onCustomMessage') conn.removeEventHandler("onCustomMessage");
		if(id==='all' || id === 'onReceivedMessage') conn.removeEventHandler("onReceivedMessage");
		// if(id==='all' || id === 'onTextMessage') conn.removeEventHandler("onTextMessage");
	},
	recallMessage(option) {// 撤回
		conn.recallMessage(option);
	},
	deleteSession(option) {// 删除会话
		const { userID, } = option;
		return conn.deleteSession({
			channel: userID, // 会话 ID（对方的 userID 或群组 ID）。
			chatType: 'singleChat', // 会话类型 singleChat（单聊） groupChat（群聊）。
			deleteRoam: true, // 是否同时删除服务端漫游消息。
		})
	}
}
conn.addEventHandler('onRecallMessage',{
	onRecallMessage: (msg) => {
		console.log('>>>收到RecallMessage-------');
	}, // 收到撤回消息。
})
conn.addEventHandler('onImageMessage', {
	onImageMessage: (msg) => {
		console.log('>>>收到ImageMessage-------');
	}, // 收到图片消息。
})
conn.addEventHandler('onLocationMessage',{
	onLocationMessage: (msg) => {
		console.log('>>>收到LocationMessage-------');
	}, // 收到位置消息。
})
conn.addEventHandler('onCustomMessage',{
	onCustomMessage: (msg) => {
		console.log('>>>收到CustomMessage---------');
	}, // 收到自定义消息。
})
// conn.addEventHandler('ReceivedMessage',{
// 	onReceivedMessage: (msg) => {
// 		console.log('>>>收到ReceivedMessage---------', msg);
// 	},    //收到消息送达服务器回执
// })
// conn.addEventHandler('ChannelMessage',{
// 	onChannelMessage:(msg) => {
// 		console.log('>>>收到ChannelMessage---------', msg);
// 	},       //收到整个会话已读的回执，在对方发送channel ack时会在这个回调里收到消息
// })
conn.listen({
	onOpened: function () {
		console.log('已连接')
	},                  //连接成功回调
	onClosed: function () {
		console.log('已关闭')
	},                  //连接关闭回调
	// onTextMessage: function ( message ) {
	// 	console.log("onTextMessage function = "+message)
	// },    //收到文本消息
	onEmojiMessage: function ( message ) {
		console.log(">>>收到onEmojiMessage-------")
	},   //收到表情消息
	// onPictureMessage: function ( message ) {
	// 	console.log("onPictureMessage", message)
	// },  //收到图片消息
	onCmdMessage: function ( message ) {
		console.log(">>>收到onCmdMessage-------")
	},     //收到命令消息
	onAudioMessage: function ( message ) {
		console.log(">>>收到onAudioMessage-------")
	},   //收到音频消息
	// onLocationMessage: function ( message ) {
	// 	console.log("onLocationMessage", message)
	// },//收到位置消息
	onFileMessage: function ( message ) {
		console.log(">>>收到onFileMessage-------")
	},    //收到文件消息
	// onCustomMessage: function ( message ) {
	// 	console.log("onCustomMessage", message)
	// },  //收到自定义消息
	onVideoMessage: function (message) {
		var node = document.getElementById('privateVideo');
		var option = {
			url: message.url,
			headers: {
				'Accept': 'audio/mp4'
			},
			onFileDownloadComplete: function (response) {
					var objectURL = WebIM.utils.parseDownloadResponse.call(conn, response);
					node.src = objectURL;
				},
			onFileDownloadError: function () {
				console.log('File down load error.')
			},
		};
		WebIM.utils.download.call(conn, option);
	},   //收到视频消息
	onPresence: function ( message ) {
		console.log(">>>收到onPresence-------")
	},       //处理“广播”或“发布-订阅”消息，如联系人订阅请求、处理群组、聊天室被踢解散等消息
	onRoster: function ( message ) {
		console.log(">>>收到onRoster-------")
	},         //处理好友申请
	onInviteMessage: function ( message ) {
		console.log(">>>收到onInviteMessage-------")
	},  //处理群组邀请
	onOnline: function () {},                  //本机网络连接成功
	onOffline: function () {},                 //本机网络掉线
	onError: function ( message ) {
		console.log(">>>收到onError-------")
	},          //失败回调
	onBlacklistUpdate: function (list) {       //黑名单变动
		// 查询黑名单，将好友拉黑，将好友从黑名单移除都会回调这个函数，list则是黑名单现有的所有好友信息
		console.log(">>>收到onBlacklistUpdate-------");
	},
	// onRecallMessage: function(message){
	// 	console.log("onRecallMessage",message)
	// },      //收到撤回消息回调
	// onReceivedMessage: function(message){
	// 	console.log("onReceivedMessage", message)
	// },    //收到消息送达服务器回执
	onDeliveredMessage: function(message){
		console.log(">>>收到onDeliveredMessage-------")
	},   //收到消息送达客户端回执
	onReadMessage: function(message){
		console.log(">>>收到onReadMessage-------")
	},        //收到消息已读回执
	onCreateGroup: function(message){
		console.log(">>>收到onCreateGroup-------")
	},        //创建群组成功回执（需调用createGroupNew）
	onMutedMessage: function(message){
		console.log(">>>收到onMutedMessage-------")
	},       //如果用户在A群组被禁言，在A群发消息会走这个回调并且消息不会传递给群其它成员
	// onChannelMessage: function(message){
	// 	console.log("onChannelMessage", message)
	// }      //收到整个会话已读的回执，在对方发送channel ack时会在这个回调里收到消息
})
if(sessionStorage.getItem('token') && !window.noIMlogin){
	let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
	if(!window.IMlogin){
		IM.login({
			id:userInfo.id,
			success:()=>{
				window.IMlogin = true
			}
		})
	}
}
export default IM
