// API
import api from "@/api/api.js";
// 所有权限
import qx from "@/common/qx.json";
import axios from "axios";
// fade/zoom 等
import "element-ui/lib/theme-chalk/base.css";
// collapse 展开折叠
import CollapseTransition from "element-ui/lib/transitions/collapse-transition";
import moment from "moment";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import "quill/dist/quill.bubble.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import Vue from "vue";
import VueAMap from "vue-amap";
import { VueCropper } from "vue-cropper";
import VueQuillEditor from "vue-quill-editor";
import TreeTable from "vue-table-with-tree-grid";
import vueToPdf from "vue-to-pdf";
import App from "./App.vue";
import "./assets/css/global.css";
import "./assets/fonts/iconfont.css";
import interview from "./common/interview/index.js";
import report from "./common/report/index.js";
import jineng from "./common/jineng/index.js";
import resumeDetail from "./common/resumeDetail/index.js";
// 视频播放组件
// import VueVideoPlayer from 'vue-video-player'
// import 'video.js/dist/video-js.css'
// Vue.use(VideoPlayer)
// 公用组件
import videoDetail from "./common/videoDetail/index.js";
// 环信方法
import IM from "./common/WebIM.js";
import wxPoput from "./common/wxCodePoput/index.js";
import "./plugins/element.js";
import router from "./router";

// 地图

Vue.prototype.$videoDetail = videoDetail; //视频详情
Vue.prototype.$resumeDetail = resumeDetail; //简历详情
Vue.prototype.$report = report; //举报弹窗
Vue.prototype.$interview = interview; //面试弹窗
Vue.prototype.$wxPoput = wxPoput; //微信登录弹窗
Vue.prototype.$jineng = jineng; //微信登录弹窗
Vue.prototype.$moment = moment;
Vue.prototype.$IM = IM;
Vue.use(vueToPdf);

Vue.component("VueCropper", VueCropper);
Vue.use(VueAMap);
Vue.component(CollapseTransition.name, CollapseTransition);
VueAMap.initAMapApiLoader({
  // 高德的key，可以注册一个高德的开发者账户进行获取，具体看高德官网流程
  // key: '79beb5fc58880b3bcf22e3cf9b119e9b',
  key: "431151948cd0e0f18d76190bb09c33b7",
  // key: '8b64632ba824a0f17673252b7a05deb7',
  uiVersion: "1.0.11", // ui库版本，不配置不加载,
  // 插件集合'AMap.Geocoder','AMap.Autocomplete','AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView',
  //'AMap.Geolocation','AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'
  plugin: [
    "AMap.Geocoder",
    "AMap.Autocomplete",
    "AMap.PlaceSearch",
    "AMap.Scale",
    "AMap.OverView",
    "AMap.Geolocation",
    "AMap.ToolBar",
    "AMap.MapType",
    "AMap.PolyEditor",
    "AMap.CircleEditor",
  ],
  // 高德 sdk 版本，默认为 1.4.4
  v: "1.4.4",
});

Vue.prototype.$api = api;

Vue.prototype.$qx = qx;
// 用户权限
// import qxuser from '@/common/qxuser.json'
// Vue.prototype.$qxuser = qxuser;

// import './common/WebIM.js'

// axios.defaults.baseURL = 'http://192.168.1.11:8201/'
axios.defaults.baseURL = "https://freemen.work";

// 请求头拦截
var token = window.sessionStorage.getItem("token");
axios.interceptors.request.use((config) => {
  NProgress.start();
  config.headers.Authorization = token;
  return config;
});

Vue.prototype.uploadheaders = {
  'X-user_session_key': token,
  'X-User': token
}

axios.interceptors.response.use((config) => {
  NProgress.done();
  return config;
});
Vue.prototype.$http = axios;

Vue.component("tree-table", TreeTable);
Vue.use(VueQuillEditor);

Vue.filter("dateFormat", (originVal) => {
  const dt = new Date(originVal);
  const y = dt.getFullYear();
  const m = (dt.getMonth() + 1 + "").padStart(2, "0");
  const d = (dt.getDate() + 1 + "").padStart(2, "0");
  const hh = (dt.getHours() + 1 + "").padStart(2, "0");
  const mm = (dt.getMinutes() + 1 + "").padStart(2, "0");
  const ss = (dt.getSeconds() + 1 + "").padStart(2, "0");
  return `${y}-${m}-${d} ${hh}:${mm}:${ss}`;
});

Vue.config.productionTip = false;
router.afterEach((to, from, next) => {
  console.log("to:", to, ", from:", from, ", next:", next);
  window.scrollTo(0, 0)
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
