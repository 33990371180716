<template>
  <div id="wxCodePoput">
    <div class="content">
      <!-- 承载二维码容器 -->
      <div class="wx_dialog_toiast_delltet" id="login_container"></div>
      <div class="wx_dialog_button_delete" @click="close_wchat_qrcode">
        <i class="el-icon-circle-close" style="color: red; font-size: 70px"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  watch: {
    $router(value) {
      console.log(value);
    },
  },
  created() {
    // this.Get_wechat_callback_information();
  },
  mounted() {
    this.$nextTick(() => {
      this.get_wx_qrcode(); //获取微信的登录二维码
    });
  },
  methods: {
    get_wx_qrcode() {
      //从微信的开发文档不难看出回调域名需要经过encodeURIComponent处理
      // let call_back_domain_name = window.location.href,
      // domain_name_length = call_back_domain_name.indexOf("#") - 1,
      // DDNS = call_back_domain_name.substring(0, domain_name_length),
      // DDNS_resolution = encodeURIComponent(DDNS);
      new WxLogin({
        self_redirect: false,
        id: "login_container", //需要承载二维码的容器id
        appid: "wx6ae4e017ec49f967",
        scope: "snsapi_login", //网页授权，目前网页只有这一种方式，静默授权
        redirect_uri: "https://manage.freemen.work/#/login", //回调域名
        // redirect_uri: "http://localhost:8080/#/login", //回调域名
        state: Math.random(),
        style: "white",
        href: "",
      });
    },
    close_wchat_qrcode() {
      this.$wxPoput.hidden();
    },
  },
};
</script>

<style lang="less" scoped>
#wxCodePoput {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  .wx_dialog_toiast_delltet {
    width: 500px;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .wx_dialog_button_delete {
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
