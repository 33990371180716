<template>
  <div id="resumeDetail">
    <el-dialog
      :visible.sync="dialogVisible"
      width="580px"
      :show-close="false"
      :modal-append-to-body="false"
      :lock-scroll="false"
    >
      <div class="details">
        <span class="closeicon" @click="closebox()"
          ><i class="el-icon-error"></i
        ></span>
        <div class="wb-tit font18">
          简历详情
          <div class="caozuo font22">
            <div class="jubao" @click="jubao()">
              <i class="el-icon-warning-outline"></i>
            </div>
            <div class="collect" @click="collect">
              <i class="el-icon-star-on" v-if="config.isStore"></i>
              <i class="el-icon-star-off" v-else></i>
            </div>
          </div>
        </div>
        <div class="infobox">
          <div class="person-info">
            <div class="p-picurl">
              <img
                :src="'https://freemen.work/user/file/view/' + config.avatar"
              />
              <span
                class="bofang font20"
                v-if="config.resUrl"
                @click="videoshow()"
                ><i class="el-icon-video-play"></i
              ></span>
            </div>
            <div class="p-news">
              <div class="pn-name">
                <div class="namemain">
                  <span class="font22">{{ config.displayName }}</span>
                  <img
                    src="../../assets/images/person/boy.png"
                    v-if="config.sex == 1"
                  />
                  <img src="../../assets/images/person/girl.png" v-else />
                </div>
                <div class="score font14">
                  <span>{{ config.score }}</span>
                </div>
              </div>
              <div class="beizhu font14">
                {{ config.expYear }}年 |
                {{
                  config.educationList && config.educationList.length &&config.educationList[config.educationList.length - 1].educationType
                    ? config.educationList[config.educationList.length - 1]
                        .educationType.itemText
                    : "/"
                }}
                |
                {{
                  new Date().getYear() -
                  new Date(config.birthday).getYear() -
                  (new Date().getMonth() > new Date(config.birthday).getMonth()
                    ? 0
                    : 1)
                }}岁 | {{ config.jobLevelNames }}
              </div>
            </div>
          </div>
          <div class="worktime">
            <div class="erji-tit font22">期望工作时间</div>
            <div class="time-main">
              <img src="../../assets/images/person/timeqi.png" />
              <div class="">
                {{ config.startDate ? config.startDate.split(" ")[0] : "/" }}
              </div>
              <span>-</span>
              <img src="../../assets/images/person/timezhi.png" />
              <div class="">
                {{ config.endDate ? config.endDate.split(" ")[0] : "/" }}
              </div>
            </div>
          </div>
          <div class="intention">
            <div class="erji-tit font22">期望职位</div>
            <div class="workinfo">
              <div class="font18">{{ config.jobName }}</div>
              <span>{{ config.city }} | {{ config.jobLevelNames }}</span>
            </div>
            <div class="workbiaoqian font14">
              <span v-for="(item, index) in config.skillIds" :key="index">{{
                item.itemText
              }}</span>
            </div>
            <div class="workexperience">
              <div class="erji-tit font22">工作经历</div>
              <div
                class="experiencelist"
                v-for="(item, index2) in config.userWorkExpList"
                :key="index2"
              >
                <div class="gs-info">
                  <div class="gi-left font18">
                    {{ item.companyName }}
                    <span class="font14">{{ item.jobName }}</span>
                  </div>
                  <div class="gi-right font14">
                    {{ item.startDate }}-{{ item.endDate }}
                  </div>
                </div>
                <!-- <div class="workbiaoqian font14">
									<span>spring</span>
									<span>springBoot</span>
									<span>spring</span>
									<span>springBoot</span>
								</div> -->
                <div class="messageall font16">
                  <div class="">{{ item.jobDescription }}</div>
                </div>
              </div>
            </div>
            <div class="workexperience">
              <div class="erji-tit font22">项目经历</div>
              <div
                class="experiencelist"
                v-for="(item, index2) in config.projectExpList"
                :key="index2"
              >
                <div class="gs-info">
                  <div class="gi-left font18">
                    {{ item.projectName }}
                  </div>
                  <div class="gi-right font14">
                    {{ item.projectStartDate }}-{{ item.projectEndDate }}
                  </div>
                </div>
                <div class="workbiaoqian font14">
                  <span v-for="(sub, i) in item.projectTechnology" :key="i">{{
                    sub.itemText
                  }}</span>
                </div>
                <div class="messageall font16">
                  <div class="">{{ item.projectDescription }}</div>
                </div>
              </div>
            </div>
            <div class="education">
              <div class="erji-tit font22">教育经历</div>
              <div
                class="education-main"
                v-for="(item, index) in config.educationList"
                :key="index"
              >
                <div class="em-left font18">
                  {{ item.schoolName }} | {{ item.major }} |
                  {{ item.educationType?item.educationType.itemText:'' }}
                </div>
                <div class="em-right font14">
                  {{ item.startDate }}-{{ item.endDate }}
                </div>
              </div>
            </div>
            <div class="education">
              <div class="erji-tit font22">资格证书</div>
              <div
                class="education-main"
                v-for="(item, index) in config.honorCertList"
                v-show="item.shCertStatus === 1"
                :key="index">
                <div class="em-left font18">
                  {{ item.name }}
                </div>
                <div v-if="item.certImage && item.certImage !== ''" class="em-left font16" style="color: #00BCFF">
                  <a @click="certShow(item)">查看证书</a>
                </div>
              </div>
            </div>
            <div class="education">
              <div class="erji-tit font22">外语能力</div>
              <div
                class="education-main"
                v-for="(item, index) in config.languageList"
                :key="index"
              >
                <div class="em-left font18">
                  {{ item.languageType.itemText }} |
                  {{ item.languageLevel.itemText }}
                </div>
                <div v-if="item.imageUrl && item.imageUrl !== ''" class="em-left font16" style="color: #00BCFF">
                  <a @click="languageCertShow(item)">查看证书</a>
                </div>
              </div>
            </div>
            <div class="btn-caozuo">
              <!-- <el-button v-if="type != 'shoucang'" type="primary" class="qx" @click="$resumeDetail.hidden()">取消</el-button> -->
              <el-button
                v-if="type != 'zaizhi' && type != 'shoucang' && type != 'toudi'"
                type="primary"
                class="yy er"
                @click="sendInterview"
                >面试邀约</el-button
              >
              <el-button
                v-if="type != 'shoucang'"
                type="primary"
                class="yy"
                @click="Chat(config)"
                >沟通</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 沟通消耗聊天卡弹窗 -->
    <el-dialog
      :visible.sync="cardDialogVisible"
      width="600px"
      center
      :show-close="false"
      :modal-append-to-body="false"
    >
      <div class="updown_box">
        <div class="ub-img">
          <img src="../../assets/images/person/yongcard.png" />
        </div>
        <div class="ub-text font22">发起聊天将消耗一次聊天卡，确认继续？</div>
        <div class="btnbox">
          <el-button
            class="qx"
            type="primary"
            ghost
            @click="cardDialogVisible = false"
            >取消</el-button
          >
          <el-button class="qr" type="primary" @click="chatCommit"
            >确定</el-button
          >
        </div>
      </div>
    </el-dialog>
    <!-- 聊天卡额度不足弹窗 -->
    <el-dialog
      :visible.sync="nocardDialogVisible"
      width="600px"
      center
      :show-close="false"
      :modal-append-to-body="false"
    >
      <div class="updown_box">
        <div class="ub-img">
          <img src="../../assets/images/person/qianbao.png" />
        </div>
        <div class="ub-text font22">当前聊天卡额度不足，是否购买？</div>
        <div class="btnbox">
          <el-button
            class="qx"
            type="primary"
            @click="nocardDialogVisible = false"
            >取消</el-button
          >
          <el-button class="qr" type="primary" @click="buyCard"
            >去购买</el-button
          >
        </div>
      </div>
    </el-dialog>
    <!-- 查看证书窗口 -->
    <el-dialog
        :visible.sync="imageDialogVisible"
        width="600px"
        :show-close="false"
        :modal-append-to-body="false"
        :lock-scroll="false">
      <div style="padding: 60px 40px;">
        <div type="card" style="display: flex; align-items: center; justify-content: center;">
          <div v-for="(item, index) in previewUrl" :key="index">
            <img style="max-width:300px;" :src="baseURL + item" />
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import Bus from '../../common/bus.js';
export default {
  data() {
    return {
      dialogVisible: true,
      config: {},
      cardDialogVisible: false,   // 消耗聊天卡弹窗
      nocardDialogVisible: false, // 聊天卡额度不足弹窗
      imageDialogVisible: false,  // 图片查看弹窗
      previewUrl: [],
      baseURL:"",
      chatConfig: {
        companyJobId: "",
        userId: "",
        userJobId: "",
      },
    };
  },
  created() {
    this.dialogVisible = this.show;
    this.getDetail();
    if (this.baseURL === ""){
      let tempUrl = window.location.origin;
      if(tempUrl.split(':').length > 2){
        this.baseURL = tempUrl.split(':')[0]+":"+tempUrl.split(':')[1]+":8200/user/file/view/"
      } else {
        this.baseURL =  tempUrl + "/user/file/view/";
      }
    }
  },
  watch: {
    dialogVisible(value) {
      if (!value) {
        this.$resumeDetail.hidden();
      }
    },
  },
  mounted() {
    this.$interview.hidden();
    // Bus.$on('searchresumedstuts', data => {
    // 	this.dialogVisible = data.show;
    // 	this.config = data.data
    // });
  },
  methods: {
    buyCard() {
      this.nocardDialogVisible = false;
      this.dialogVisible = false;
      window.location.href = window.location.origin + "/#/propsmall";
    },
    getDetail() {
      this.$api
        .getCvdetail("get", {
          companyJobId: this.companyJobId,
          userJobId: this.userJobId,
        })
        .then((res) => {
          this.config = res.data;
        });
    },
    collect() {
      let config = {
        userId: this.config.userId,
        companyJobId: this.config.companyJobId,
        userJobId: this.config.userJobId,
        id: this.config.id,
      };
      this.$api.collect("post", config).then(() => {
        this.config.isStore = !this.config.isStore;
        this.getDetail();
        this.$message({
          message: "操作成功",
          type: "success",
        });
      });
    },
    closebox() {
      this.dialogVisible = false;
    },
    certShow(data){
      console.log("certShow ---- ",data);
      if(!data || data.certImage === null) return;
      this.previewUrl = [];
      this.previewUrl = data.certImage.split(',');
      console.log("TODO ----- certShow previewUrl", this.previewUrl);
      this.imageDialogVisible = true;
    },
    languageCertShow(data){
      console.log("languageCertShow ---- ",data);
      if(!data || data.imageUrl === null) return;
      this.previewUrl = [];
      this.previewUrl.push(data.imageUrl);
      console.log("TODO ----- languageCertShow previewUrl", this.baseURL," --- ",this.previewUrl);
      this.imageDialogVisible = true;
    },
    // 视频详情
    videoshow() {
      let config = {
        show: true,
        data: this.config,
      };
      this.$videoDetail.show(config);
    },
    // 举报
    jubao() {
      let config = {
        show: true,
        data: this.config,
      };
      this.$report.show(config);
      // Bus.$emit('reportdstuts', config);
    },
    sendInterview() {
      this.dialogVisible = false;
      this.$interview.show({
        show: true,
        jobList: this.positionoptions,
        userInfo: this.config,
      });
    },
    Chat(data) {
      this.dialogVisible = false;

      this.chatConfig.companyJobId = data.companyJobId;
      this.chatConfig.userId = data.userId;
      this.chatConfig.userJobId = data.userJobId;
      if (this.type == "toudi") {
        this.$api
          .checkChatCard("get", {
            companyJobId: data.companyJobId,
            userJobId: data.userJobId,
          })
          .then((res) => {
            if (res.data > 0) {
              this.dialogVisible = false;
              // this.$router.push('chatmain')
              this.callback();
            } else {
              this.cardDialogVisible = true;
            }
          });
      } else {
        this.$api
          .checkChatCard("get", {
            companyJobId: data.companyJobId,
            userJobId: data.userJobId,
          })
          .then((res) => {
            if (res.data > 0) {
              this.dialogVisible = false;
              // this.$router.push('chatmain')
              this.callback();
            } else {
              this.$api.freeChat("post", this.chatConfig).then(() => {
                this.dialogVisible = false;
                // this.$router.push('chatmain')
                this.callback();
              });
            }
          });
      }
    },
    chatCommit() {
      if (this.type == "toudi") {
        this.$api.useChatCard("post", this.chatConfig).then((res) => {
          this.cardDialogVisible = false;
          if (res.code == "10901") {
            this.nocardDialogVisible = true;
          } else {
            this.dialogVisible = false;
            // this.$router.push('chatmain')
            this.callback();
          }
        });
      }
    },
  },
};
</script>
<style type="text/css">
.el-dialog__header,
.el-dialog__body {
  padding: 0 !important;
}
</style>
<style lang="less" scoped>
.closeicon {
  position: absolute;
  top: -40px;
  right: 0;
  font-size: 26px;
  color: #f3f3f3;
}
.details {
  .wb-tit {
    padding: 17px 0;
    text-align: center;
    background-color: #f3f3f3;
    color: #000000;
    font-weight: 600;
    position: relative;
    .caozuo {
      position: absolute;
      top: 17px;
      right: 30px;
      display: flex;
      align-items: center;
      .jubao {
      }
      .collect {
        margin-left: 14px;
        .el-icon-star-off,
        .el-icon-star-on {
          color: #ffa800;
        }
      }
    }
  }
}

.infobox {
  padding: 40px 30px;
  .person-info {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f3f3f3;
    padding-bottom: 20px;
    .p-picurl {
      margin-right: 20px;
      position: relative;
      img {
        width: 50px;
        height: 50px;
      }
      .bofang {
        position: absolute;
        top: -5px;
        right: -5px;
        border-radius: 50px;
        color: #3f3f3f;
      }
    }
    .p-news {
      display: flex;
      flex: 1;
      flex-direction: column;
      line-height: 1.6;
      .pn-name {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .namemain {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: #111111;
          .n-name {
            filter: blur(3px);
          }
          img {
            width: 24px;
            height: 24px;
            margin: 0 18px 0 10px;
          }
          span:last-child {
            background-color: #e9f9ff;
            color: #00bcff;
            padding: 2px 10px;
          }
        }
        .score {
          background-color: #00bcff;
          border-radius: 8px 8px 8px 0;
          color: #ffffff;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .beizhu {
        color: #666666;
      }
    }
  }
  .erji-tit {
    margin: 20px 0 20px 0;
    color: #111111;
  }
  .worktime {
    .time-main {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-bottom: 20px;
      border-bottom: 1px solid #f3f3f3;
      img {
        width: 50px;
        height: 50px;
        margin-right: 5px;
      }
      span {
        margin: 0 30px;
      }
    }
  }
  .intention {
    .workinfo {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #111111;
      font-weight: 600;
      span {
        color: #666666;
        margin-left: 10px;
        font-weight: 500;
      }
    }
    .workbiaoqian {
      padding: 20px 0;
      border-bottom: 1px solid #f3f3f3;
      display: flex;
      flex-wrap: wrap;
      span {
        background-color: #eeeeee;
        padding: 4px 8px;
        margin: 0 10px 10px 0;
        color: #666666;
      }
    }
    .workexperience {
      .experiencelist {
        border-bottom: 1px solid #f3f3f3;
        padding-bottom: 15px;
        margin-top: 30px;
        .gs-info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .gi-left {
            color: #111111;
            span {
              color: #666666;
              margin-left: 15px;
            }
          }
          .gi-right {
            color: #111111;
          }
        }
        .workbiaoqian {
          padding: 20px 0;
          border-bottom: 1px solid #f3f3f3;
          display: flex;
          flex-wrap: wrap;
          span {
            background-color: #eeeeee;
            padding: 4px 8px;
            margin: 0 10px 10px 0;
            color: #666666;
          }
        }
        .messageall {
          margin-top: 20px;
          color: #333333;
          line-height: 2;
        }
      }
    }
    .education {
      border-bottom: 1px solid #f3f3f3;
      padding-bottom: 20px;
      .education-main {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #111111;
        .em-left {
          color: #333;
        }
        .em-right {
        }
      }
    }
    .btn-caozuo {
      margin-top: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      /deep/ .el-button {
        border-radius: 0px;
        background-color: #00bcff;
        border: none;
      }
      .qx {
        border: 1px solid #00bcff;
        background-color: #ffffff;
        color: #00bcff;
      }
      .yy {
        width: 350px;
      }
    }
  }
}
.updown_box {
  padding: 20px;
  text-align: center;
}
.updown_box .ub-img img {
  width: 50px;
}
.ub-text {
  margin: 20px 0 50px 0;
}
.updown_box .qx {
  border: 1px solid #00bcff;
  background-color: #ffffff;
  color: #00bcff;
  width: 150px;
  margin-right: 20px;
}
.updown_box .qr {
  background-color: #00bcff;
  border: 1px solid #00bcff;
  width: 150px;
  margin-left: 20px;
}
</style>
