import { $http } from "@/utils/requestfu";

let config = {
  getdict: "/company/system/dict", //获取字典
  register: "/company/login/web/register", //注册接口
  phonemsg: "/company/login/msg", //短信接口
  emailmsg: "/company/login/mail/code", //发送邮箱验证码接口
  codelogin: "/company/login/web/codeLogin", //手机验证码登录
  passlogin: "/company/login/web/passwordLogin", //密码登录
  outlogin: "/company/login/web/logout", //退出登录
  phoneRepassword: "/company/login/resetPassword", //手机重置密码
  emailRepassword: "/company/login/mail/resetPassword", //邮箱重置密码
  getqrcode: "/company/pcLogin/qrcode", //获取二维码登录
  wxCallback: "/company/pcLogin/wx/web/callback", //微信回调
  wxLogin: "/company/login/wx/web/login", //微信登录

  companyca: "/company/company/ca", //企业实名认证
  paysubmit: "/company/company/initPay", //打款提交审核
  checkPay: "/company/company/checkPay",

  joblist: "/company/job/list", //职位列表
  jobdetail: "/company/job/detail", //职位详情
  editjobtype: "/company/job/publish", //修改职位状态
  savejob: "/company/job/save", //保存招聘职位
  getJoblevel: "/company/job/getJobLevelList", //获取职级列表
  saveJoblevel: "/company/job/saveJobLevel", //保存职级
  applyTemp: "/company/company/applyTemp", //驻场申请

  getPhrase: "/company/phrase/list", //获取常用语
  delPhrase: "/company/phrase/del", //删除
  savePhrase: "/company/phrase/save", //保存

  updatePhone: "/company/user/updatePhone", //更换手机号
  updatePassword: "/company/user/updatePassword", //更改密码

  warn: "/company/system/warn", //举报
  advise: "/company/system/advise", //意见反馈

  updateUserInfo: "/company/user/update", //修改用户信息

  getAccountList: "/company/account/list", //获取账号列表
  saveAccount: "/company/account/save", //保存子账号
  delAccount: "/company/account/del", //删除子账号
  changeAdmin: "/company/account/changeAdmin", //转移权限

  getUserInfo: "/company/user/getInfo", //获取用户信息
  saveUserInfo: "/company/company/updateCompany", //保存用户信息

  getWorkSet: "/company/job/getWorkSet", //查询考勤设置
  saveWorkSet: "/company/job/saveWorkSet", //保存考勤设置

  getPropsList: "/company/product/list", //获取道具列表
  aliPay: "/company/order/aliPayWeb", //支付宝支付
  wxPay: "/company/order/wxPayWeb", //微信支付
  payInfo: "/company/pay/info", //支付结果
  customPack: "/company/product/customization", //定制套餐
  propRecord: "/company/product/order", //购买记录
  upload: "/company/file/upload", //上传

  searchPerson: "/company/search/job", //搜索牛人
  getCvdetail: "/company/cv/detail", //获取简历详情
  collect: "/company/store/save", //收藏
  noPass: "/company/push/noPass", //不合适
  pass: "/company/push/pass", //取消不合适

  getHandData: "/company/push/getData", //获取握手数据
  getHangList: "/company/push/list", //获取握手列表
  handNopass: "/company/push/noPass", //不合适
  hangPass: "/company/push/pass", //移出回收站
  hangDetail: "/company/push/read", //握手详情
  sendInterview: "/company/interview/send", //发送面试邀请
  sendMessage: "/company/interview/sendMessage", //发送信息
  allInterviewSee: "/company/interview/batchInterview", //批量面试预览
  saveAllinyerview: "/company/interview/saveBatchInterview", //批量面试保存

  getWorkList: "/company/work/list", //获取在职人员列表
  quit: "/company/work/quit", //退场
  delay: "/company/work/delay", //延期
  workWarn: "/company/work/warn", //警告
  workScore: "/company/work/score", //打分

  getCollectList: "/company/store/list", //获取收藏列表

  getPostList: "/company/post/list", //获取投递列表
  postNopass: "/company/post/noPass", //不合适

  getInterviewList: "/company/interview/list", //获取面试列表
  interviewRead: "/company/interview/read", //已读
  reSendInterview: "/company/interview/save", //重新发送面试邀请

  sendOffer: "/company/offer/send", //发送offer
  getOfferList: "/company/offer/list", //获取offer列表
  offerAccept: "/company/offer/accept", //同意重新接受
  reSendOffer: "/company/offer/reSend", //重新发送
  readOffer: "/company/offer/read", //已读
  upDateOffer: "/company/offer/update", //修改offe

  checkChatCard: "/company/cv/checkChatCard", //检查聊天卡
  freeChat: "/company/cv/freeChat", //免聊天卡
  getTalkList: "/company/cv/getTalkList", //获取沟通列表
  getChatRecord: "/company/cv/getChatRecord", //获取微信手机号记录
  useChatCard: "/company/cv/useChatCard", //使用聊天卡
  getMesList: "/company/message/list", //获取信息列表
  messageRead: "/company/message/read", //已读
  getNoreadList: "/company/message/unReadCount", //获取未读信息
  checkSave: "/company/job/checkSave", ////职级未设置 10802 考勤未设置10801
  getQuestionList: "/company/system/question", //获取常见问题列表
  agreement: "/company/system/agreement",
  extList: "/company/push/extList",
  getToken: "/company/pcLogin/getToken",  // 获取登入token
  getDetail: "/company/user/getDetail",   // 获取技术者详情

  getSkillList: "/company/system/getSkillDict", //获取技能列表
};

let exports = {};
for (var k in config) {
  let url = config[k];
  exports[k] = function(type, data) {
    if (type == "get") {
      return $http.get(url, data);
    } else {
      return $http.post(url, data);
    }
  };
}

export default exports;
