import qs from "qs";
import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const Admin = (resolve) => require(["../views/Homenew"], resolve);
const problem = (resolve) => require(["../components/problem/index"], resolve);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: (resolve) => require(["../components/Login.vue"], resolve),
    hidden: true,
  },
  // {
  // 		path: '/Welcome',
  // 		name: 'Welcome',
  // 		component: resolve => require(['../components/Welcome.vue'], resolve),
  // 		hidden: true
  // },

  {
    path: "/realname",
    name: "realname",
    component: Admin,
    // component: resolve => require(['../components/realname.vue'], resolve),
      children: [
      {
        path: "/realnames",
        component: (resolve) =>
          require(["../components/realname.vue"], resolve),
        name: "企业实名认证",
        hidden: true,
        meta: {
          keepAlive: true,
        },
      },
    ],
  },
  {
    path: "/home",
    name: "商户管理",
    component: Admin,
    children: [
      {
        path: "/induction",
        component: (resolve) =>
          require(["../components/position/induction.vue"], resolve),
        name: "入职职位管理",
        hidden: true,
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/settled",
        component: (resolve) =>
          require(["../components/position/settled.vue"], resolve),
        name: "入驻管理",
        hidden: true,
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/addposition",
        component: (resolve) =>
          require(["../components/position/addposition.vue"], resolve),
        name: "发布入职职位",
        hidden: true,
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/editposition",
        component: (resolve) =>
          require(["../components/position/editposition.vue"], resolve),
        name: "入职职位编辑",
        hidden: true,
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/editrecruit",
        component: (resolve) =>
          require(["../components/position/editrecruit.vue"], resolve),
        name: "入驻职位编辑",
        hidden: true,
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/recruitmentapplication",
        component: (resolve) =>
          require([
            "../components/position/recruitmentapplication.vue",
          ], resolve),
        name: "入驻招聘申请",
        hidden: true,
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/recruitmentlist",
        component: (resolve) =>
          require(["../components/position/recruitmentlist.vue"], resolve),
        name: "入驻职位管理",
        hidden: true,
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/addsettled",
        component: (resolve) =>
          require(["../components/position/addsettled.vue"], resolve),
        name: "发布入驻职位",
        hidden: true,
        meta: {
          keepAlive: true,
        },
      },
    ],
  },
  {
    path: "/persones",
    name: "牛人管理",
    component: Admin,
    children: [
      {
        path: "/handshake",
        component: (resolve) =>
          require(["../components/person/handshake.vue"], resolve),
        name: "已握手牛人",
        hidden: true,
        meta: {
          keepAlive: true,
        },
      },
      // {
      // 	path: '/searchP',
      // 	component: resolve => require(['../components/person/searchP.vue'], resolve),
      // 	name: '搜索牛人',
      // 	hidden: true,
      // 	meta: {
      // 		keepAlive: true
      // 	},
      // },
      {
        path: "/onjob",
        component: (resolve) =>
          require(["../components/person/onjob.vue"], resolve),
        name: "在职牛人",
        hidden: true,
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/interviewP",
        component: (resolve) =>
          require(["../components/person/interviewP.vue"], resolve),
        name: "面试牛人",
        hidden: true,
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/offerP",
        component: (resolve) =>
          require(["../components/person/offerP.vue"], resolve),
        name: "offer管理",
        hidden: true,
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/toudiP",
        component: (resolve) =>
          require(["../components/person/toudiP.vue"], resolve),
        name: "投递牛人",
        hidden: true,
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/collectP",
        component: (resolve) =>
          require(["../components/person/collectP.vue"], resolve),
        name: "收藏牛人",
        hidden: true,
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/trash",
        component: (resolve) =>
          require(["../components/person/trash.vue"], resolve),
        name: "人才库",
        hidden: true,
        meta: {
          keepAlive: true,
        },
      },
    ],
  },
  {
    path: "/chat",
    name: "沟通",
    component: Admin,
    children: [
      {
        path: "/chatmain",
        component: (resolve) =>
          require(["../components/chat/chatmain.vue"], resolve),
        name: "沟通",
        hidden: true,
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/message",
        name: "系统消息",
        component: (resolve) =>
          require(["../components/chat/message.vue"], resolve),
        hidden: true,
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/previewresume",
        component: (resolve) =>
          require(["../components/chat/previewresume.vue"], resolve),
        name: "预览简历",
        hidden: true,
        meta: {
          keepAlive: true,
        },
      },

      {
        path: "/aboutus",
        component: (resolve) => require(["../components/aboutus.vue"], resolve),
        name: "关于我们",
        hidden: true,
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/about",
        name: "问题",
        component: problem,
        children: [
          {
            path: "/commonwt",
            component: (resolve) =>
              require(["../components/problem/common.vue"], resolve),
            name: "常见问题",
            hidden: true,
            meta: {
              keepAlive: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/searchman",
    name: "搜索",
    component: Admin,
    children: [
      {
        path: "/searchpage",
        component: (resolve) =>
          require(["../components/person/searchP.vue"], resolve),
        name: "搜索牛人",
        hidden: true,
        meta: {
          keepAlive: true,
        },
      },
    ],
  },
  {
    path: "/Prop",
    name: "道具管理",
    component: Admin,
    children: [
      {
        path: "/propsmall",
        component: (resolve) =>
          require(["../components/prop/propsmall.vue"], resolve),
        name: "道具商城",
        hidden: true,
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/buyrecord",
        component: (resolve) =>
          require(["../components/prop/buyrecord.vue"], resolve),
        name: "购买记录",
        hidden: true,
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/payGateWay",
        component: (resolve) =>
          require(["../components/prop/applyText.vue"], resolve),
        hidden: false,
        meta: {
          keepAlive: true,
        },
      },
    ],
  },
  {
    path: "/enterprise",
    name: "企业基础信息",
    component: Admin,
    children: [
      {
        path: "/basicinfo",
        component: (resolve) =>
          require(["../components/enterprise/basicinfo.vue"], resolve),
        name: "企业基本信息",
        hidden: true,
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/photoalbum",
        component: (resolve) =>
          require(["../components/enterprise/photoalbum.vue"], resolve),
        name: "企业基本信息",
        hidden: true,
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/worktime",
        component: (resolve) =>
          require(["../components/enterprise/worktime.vue"], resolve),
        name: "企业基本信息",
        hidden: true,
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/positionlevel",
        component: (resolve) =>
          require(["../components/enterprise/positionlevel.vue"], resolve),
        name: "企业基本信息",
        hidden: true,
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/attestation",
        component: (resolve) =>
          require(["../components/enterprise/attestation.vue"], resolve),
        name: "企业认证信息",
        hidden: true,
        meta: {
          keepAlive: true,
        },
      },
    ],
  },
  {
    path: "/user",
    name: "账号管理",
    component: Admin,
    children: [
      {
        path: "/personaldata",
        component: (resolve) =>
          require(["../components/user/personaldata.vue"], resolve),
        name: "个人资料",
        hidden: true,
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/accountnumber",
        component: (resolve) =>
          require(["../components/user/accountnumber.vue"], resolve),
        name: "账号关联",
        hidden: true,
        meta: {
          keepAlive: true,
        },
      },
    ],
  },
  {
    path: "/setting",
    name: "设置",
    component: Admin,
    children: [
      {
        path: "/commonwords",
        component: (resolve) =>
          require(["../components/set/commonwords.vue"], resolve),
        name: "常用语设置",
        hidden: true,
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/changephone",
        component: (resolve) =>
          require(["../components/set/changephone.vue"], resolve),
        name: "修改手机号",
        hidden: true,
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/changepassword",
        component: (resolve) =>
          require(["../components/set/changepassword.vue"], resolve),
        name: "修改密码",
        hidden: true,
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/help",
        component: (resolve) =>
          require(["../components/set/help.vue"], resolve),
        name: "反馈与帮助",
        hidden: true,
        meta: {
          keepAlive: true,
        },
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  // 对于页面跳转，全部都返回到页面顶部。
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

// const routerPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//   return routerPush.call(this, location).catch(error=> error)
// }

// 路由导航守卫
router.beforeEach((to, from, next) => {
  if (to.path == "/chatmain") {
    window.noIMlogin = true;
  } else {
    window.noIMlogin = false;
  }
  const wx_url = window.location.href;
  const parseUrl = qs.parse(wx_url.split("?")[1]);
  if (parseUrl.code) {
    router.back(-1);
    window.sessionStorage.setItem("wxCode", JSON.stringify(parseUrl.code));
  }
  if (to.path === "/login") return next();
  if (to.path === "/register") return next();
  if (to.path === "/realnames") return next();
  const tokenStr = window.sessionStorage.getItem("token");
  if (!tokenStr) return next("/login");
  next();
});
export default router;
