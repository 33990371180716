import Vue from 'vue'
import interview from './interview.vue'
let Interview =Vue.extend((interview))
let instance
var instal={}
instal.show= function (item){
    instance = new Interview({
        data: {
            show: item.show,
            jobList: item.jobList,
            userInfo: item.userInfo,
            selected: item.selected ? item.selected : -1,
            cardCheck: item.cardCheck ? item.cardCheck : -1,
        }
    })
    instance.$mount()
    if(!document.getElementById('interview')){
      document.body.appendChild(instance.$el)
    }
  }

  instal.hidden=function(){//移除事件
    if(document.getElementById('interview')){
      document.body.removeChild(document.getElementById('interview'))
    }
  }
  export default instal
