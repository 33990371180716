<template>
  <div id="interview">
    <el-dialog
      :visible.sync="interview"
      width="700px"
      center
      :show-close="false"
      :modal-append-to-body="false"
      class="batch-box-out"
    >
      <div class="batch-box">
        <span class="closeicon" @click="interview = false">
          <i class="el-icon-error"></i>
        </span>
        <div class="wb-tit font18">面试邀约</div>
        <div class="frombox">
          <el-form
            :model="interviewForm"
            ref="interviewForm"
            size="small"
            label-width="130px"
            class="demo-ruleForm"
          >
            <el-form-item
              label="面试职位："
              prop="companyJobId"
              :rules="[{ required: true, message: '请选择面试职位' }]"
            >
              <el-select
                :disabled="jobSelectDisable"
                v-model="interviewForm.companyJobId"
                placeholder="请选择面试职位"
                style="width: 440px"
              >
                <el-option
                  v-for="item in jobList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="面试时间："
              prop="interviewDate"
              :rules="[{ required: true, message: '请选择日期或时间' }]"
            >
              <el-date-picker
                v-model="interviewForm.interviewDate"
                type="date"
                placeholder="请选择日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd HH:mm:ss"
                :picker-options="pickerOptions0"
                style="margin-right: 20px"
              >
              </el-date-picker>
              <el-time-picker
                v-model="interviewTime"
                placeholder="请选择时间"
                format="HH:mm"
                style="width: 150px"
              >
              </el-time-picker>
              <!-- <el-time-select
                v-model="interviewTime"
                :picker-options="{
                  start: '08:30',
                  step: '00:15',
                  end: '18:30',
                }"
                placeholder="请选择时间"
                style="width: 200px"
              >
              </el-time-select> -->
            </el-form-item>
            <el-form-item
              label="面试方式："
              prop="interviewType"
              :rules="[{ required: true, message: '请选择面试方式' }]"
            >
              <el-select
                v-model="interviewForm.interviewType"
                placeholder="请选择面试方式"
                style="width: 440px"
              >
                <el-option
                  v-for="item in fsoptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="面试地点："
              prop="interviewAddress"
              :rules="[{ required: true, message: '请选择面试地点' }]"
              v-if="interviewForm.interviewType == '1'"
            >
              <el-input
                v-model="adress"
                placeholder="请选择面试地点"
                @click.native="workDialogVisible = true"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item label="详细地址：" prop="interviewBuild" :rules="[{ required: true, message: '请选择面试地点' }]" v-if="interviewForm.interviewType=='1'">
							<el-input v-model="interviewForm.interviewBuild" placeholder="请选择面试地点"  @click.native="workDialogVisible = true"></el-input>
						</el-form-item> -->
            <el-form-item
              label="面试联系人："
              prop="interviewContact"
              :rules="[{ required: true, message: '请输入面试联系人' }]"
            >
              <el-input
                v-model="interviewForm.interviewContact"
                placeholder="请输入面试联系人"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="联系方式："
              prop="interviewPhone"
              :rules="[{ required: true, message: '请输入联系方式' }]"
            >
              <el-input
                v-model="interviewForm.interviewPhone"
                placeholder="请输入联系方式"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item label="备注：" prop="beizhu">
							<el-input
								type="textarea"
								:autosize="{ minRows: 8, maxRows: 10 }"
								maxlength="500"
								show-word-limit
								placeholder="请输入备注事项"
								v-model="interviewForm.beizhu"
								style="width: 440px;"
							></el-input>
						</el-form-item> -->
            <el-form-item>
              <el-button
                type="primary"
                class="btn-next"
                @click="submitForm('interviewForm')"
                >确定发送</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="workDialogVisible"
      width="600px"
      center
      :show-close="false"
      :modal-append-to-body="false"
      class="work-box-out"
    >
      <div class="work-box">
        <span class="closeicon" @click="workDialogVisible = false">
          <i class="el-icon-error"></i>
        </span>
        <div class="wb-tit font20">工作地点</div>
        <div class="frombox">
          <el-form
            :model="interviewForm"
            ref="workForm"
            label-width="150px"
            class="demo-ruleForm"
          >
            <el-form-item
              label="街道/写字楼："
              prop="interviewAddress"
              :rules="[{ required: true, message: '请填写真实地址' }]"
            >
              <el-select
                v-model="tipInput"
                class="tipInput"
                filterable
                remote
                reserve-keyword
                clearable
                placeholder="请输入行政区、街道、写字楼"
                :remote-method="remoteMethod"
                :loading="loading"
                @change="tipChange"
                style="width: 100%"
              >
                <el-option
                  v-for="item in tips"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                  <span style="float: left">{{ item.name }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{
                    item.district
                  }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="门牌号："
              prop="interviewBuild"
              :rules="[{ required: true, message: '请填写真实地址' }]"
            >
              <el-input
                type="text"
                style="width: 100%"
                v-model="interviewForm.interviewBuild"
                placeholder="请输入行政区、街道、写字楼"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <img
              v-if="!markers.length"
              class="amap-wrapper"
              style="width: 100%"
              src="../../assets/images/common/map.png"
            />

            <div class="amap-wrapper" v-else style="width: 100%">
              <el-amap
                class="amap-box"
                :zoom="zoom"
                :center="center"
                :mapStyle="mapStyle"
              >
                <el-amap-marker
                  v-for="(marker, index) in markers"
                  :position="marker.position"
                  :events="marker.events"
                  :vid="index"
                  :icon="marker.icon"
                  v-bind:key="index"
                >
                </el-amap-marker>
                <el-amap-info-window
                  v-if="window"
                  :position="window.position"
                  :visible="window.visible"
                  :content="window.content"
                  :offset="window.offset"
                  :is-custom="true"
                >
                  <div id="info-window">
                    <p>{{ window.address }}</p>
                  </div>
                </el-amap-info-window>
              </el-amap>
            </div>
            <el-form-item>
              <el-button class="qux" @click="workDialogVisible = false"
                >取消</el-button
              >
              <el-button type="primary" @click="saveAdress('workForm')"
                >保存地址</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog>
    <!-- 短信通知弹窗 -->
    <el-dialog
      :visible.sync="senddxDialogVisible"
      width="600px"
      center
      :show-close="false"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="updown_box">
        <div class="ub-img">
          <img src="../../assets/images/common/gou-chenggong.png" />
        </div>
        <div class="ub-text font22">面试发送成功，是否短信通知</div>
        <div class="btnbox">
          <el-button class="qx" type="primary" @click="cancelSms"
            >取消</el-button
          >
          <el-button class="qr" type="primary" @click="sendMes">通知</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 短信不足弹窗 -->
    <el-dialog
      :visible.sync="dxbzDialogVisible"
      width="600px"
      center
      :show-close="false"
      :modal-append-to-body="false"
    >
      <div class="updown_box">
        <div class="ub-img">
          <img src="../../assets/images/person/failcha.png" />
        </div>
        <div class="ub-text font22">当前短信卡额度不足，是否购买？</div>
        <div class="btnbox">
          <el-button class="qx" type="primary" @click="cancel1">取消</el-button>
          <el-button class="qr" type="primary" @click="buySms"
            >去购买</el-button
          >
        </div>
      </div>
    </el-dialog>
    <!-- 聊天卡使用弹窗 -->
    <el-dialog
        :visible.sync="cardDialogVisible"
        width="600px"
        center
        :show-close="false"
        :modal-append-to-body="false"
    >
      <div class="updown_box">
        <div class="ub-img">
          <img src="../../assets/images/person/yongcard.png" />
        </div>
        <div class="ub-text font22">发起{{ cardDialogFlag === 0 ? "聊天" : "面试" }}将消耗一次聊天卡，确认继续？</div>
        <div class="btnbox">
          <el-button
              class="qx"
              type="primary"
              @click="cardDialogVisible = false"
          >取消</el-button
          >
          <el-button class="qr" type="primary" @click="chatCommit"
          >确认</el-button
          >
        </div>
      </div>
    </el-dialog>
    <!-- 聊天卡额度不足弹窗 -->
    <el-dialog
        :visible.sync="nocardDialogVisible"
        width="600px"
        center
        :show-close="false"
        :modal-append-to-body="false"
    >
      <div class="updown_box">
        <div class="ub-img">
          <img src="../../assets/images/person/qianbao.png" />
        </div>
        <div class="ub-text font22">当前聊天卡额度不足，是否购买？</div>
        <div class="btnbox">
          <el-button
              class="qx"
              type="primary"
              @click="nocardDialogVisible = false"
          >取消</el-button
          >
          <el-button class="qr" type="primary" @click="buyCard"
          >去购买</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import location from "../../assets/images/size.png";

export default {
  data() {
    return {
      zoom: 14,
      center: [],
      mapStyle: "amap://styles/8b6be8ec497009e17a708205348b899a", //设置地图样式
      markers: [],
      windows: [],
      tipInput: "",
      loading: false,
      map: undefined,
      tips: [],
      window: "",
      interview: false,         // 面试弹窗 :visible.sync
      workDialogVisible: false,
      cardDialogVisible: false, // 消耗聊天卡弹窗
      cardDialogFlag: 0,    // 聊天卡弹窗类型 0: 聊天/沟通; 1: 面试
      nocardDialogVisible: false, //聊天卡额度不足弹窗
      interviewForm: {
        companyJobId: "",
        interviewDate: "",
        interviewAddress: "",
        interviewBuild: "",
        interviewType: "",
        interviewContact: "",
        interviewPhone: "",
        userId: "",
        userJobId: "",
        lat: 0,
        lng: 0,
      },
      interviewDate: "",
      interviewTime: "",
      fsoptions: [
        { value: 1, label: "线下面试" },
        { value: 2, label: "线上面试" },
      ],
      adress: "",
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7; //如果没有后面的-8.64e7就是不可以选择今天的
        },
      },
      senddxDialogVisible: false,
      dxbzDialogVisible: false,
      sendList: [],
      jobSelectDisable: false,
    };
  },
  watch: {
    interview(value) {
      if (!value) {
        this.$interview.hidden();
      }
    },
  },
  created() {
    this.interview = this.show;
    if(this.selected && this.selected > 0){
      this.interviewForm.companyJobId = this.selected;
      // this.jobSelectDisable = true;
      this.jobSelectDisable = false;
    } else {
      this.interviewForm.companyJobId = "";
      this.jobSelectDisable = false;
    }
    console.log("interview ---- created ### cardCheck = ", this.cardCheck)
    if(this.cardCheck && this.cardCheck !== -1){
      this.cardDialogFlag = this.cardCheck;
    } else {
      this.cardDialogFlag = 0;
    }
  },
  methods: {
    cancel1() {
      this.interview = false;
      this.$interview.hidden();
    },
    tipChange(value) {
      for (const tip of this.tips) {
        if (value === tip.id) {
          this.interviewForm.interviewAddress = tip.name;
          let markers = [];
          let windows = [];
          let basePosition = [tip.location.lng, tip.location.lat];
          // let icon = new AMap.Icon({
          // 	image: location,
          // 	size: new AMap.Size(2, 2),
          // 	imageSize: new AMap.Size(2, 2)
          // });
          // content添加相应的内容**********
          markers.push({
            position: basePosition,
            icon: new AMap.Icon({
              image: location,
              size: new AMap.Size(40, 40),
              imageSize: new AMap.Size(40, 40),
            }),
            text: "content1111",
            offset: [0, -50],
          });
          windows.push({
            position: basePosition,
            isCustom: true,
            offset: [10, -40], // 窗体偏移
            visible: true, // 初始是否显示
            address: tip.name,
          });
          this.zoom = 15;
          this.markers = markers;
          this.windows = windows;
          this.center = [tip.location.lng, tip.location.lat];
          this.window = windows[0];
          break;
        }
      }
    },
    buySms() {
      this.interview = false;
      this.$interview.hidden();

      window.location.href = window.location.origin + "/#/propsmall";
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        const that = this;
        AMap.plugin("AMap.Autocomplete", function () {
          // 实例化Autocomplete
          const autoOptions = {
            city: "全国",
          };
          const autoComplete = new AMap.Autocomplete(autoOptions);
          autoComplete.search(query, function (status, result) {
            console.log(result);
            // 搜索成功时，result即是对应的匹配数据
            that.tips = result.tips || [];
            that.loading = false;
          });
        });
      } else {
        this.tips = [];
      }
    },
    closebox() {
      this.interview = false;
    },
    saveAdress(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.workDialogVisible = false;
          this.adress =
            this.interviewForm.interviewAddress +
            this.interviewForm.interviewBuild;
        } else {
          return false;
        }
      });
    },
    submitForm(formName) {
      // if(this.interviewDate && this.interviewTime){
      // 	let y = this.interviewDate.getFullYear()
      // 	let m = this.interviewDate.getMonth()+1
      // 	let d = this.interviewDate.getDate()
      // 	this.interviewForm.interviewDate = y+'-'+m+'-'+d+' '+this.interviewTime+':00'
      // }
      if (this.interviewDate) {
        let y = this.interviewDate.getFullYear();
        let m = this.interviewDate.getMonth() + 1;
        let d = this.interviewDate.getDate();
        this.interviewForm.interviewDate = y + "-" + m + "-" + d;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.interviewForm.userId = this.userInfo.userId;
          this.interviewForm.userJobId = this.userInfo.userJobId;
          // 聊天卡使用检测
          this.$api.checkChatCard("get",{
                companyJobId: this.interviewForm.companyJobId,
                userJobId: this.userInfo.userJobId,
              }).then((res) => {
                // 无需使用聊天卡
                if (res.data > 0) {
                  this.ApiSendInterview();
                } else {
                  // 弹窗提示使用聊天卡
                  this.cardDialogVisible = true;
                }
              })
        } else {
          return false;
        }
      });
    },
    chatCommit() {
      // 使用聊天卡api所需参数
      let chatConfig = {
        companyJobId: this.interviewForm.companyJobId,
        userId: this.interviewForm.userId,
        userJobId: this.interviewForm.userJobId,
      };
      this.$api.useChatCard("post", chatConfig).then((res) => {
        this.cardDialogVisible = false;
        if (res.code === 10901) {
          // 聊天卡不足提示
          this.nocardDialogVisible = true;
        } else if(res.code === 10200){
          // 聊天卡使用成功
          if(this.cardDialogFlag === 0){
            this.selectedLabel = this.positionoptions[this.selectIndex].label;
            let tempQuery = {
              active: 1,
              companyJobId: this.interviewForm.companyJobId,
              companyJobName: this.selectedLabel,
              userJobId: this.interviewForm.userJobId,
              userId: this.interviewForm.userId,
            }
            this.$router.push({path:"chatmain", query: tempQuery})
          } else if(this.cardDialogFlag === 1) {
            this.ApiSendInterview();
          }
        }
      });
    },
    buyCard() {
      this.nocardDialogVisible = false;
      this.dialogVisible = false;
      window.location.href = window.location.origin + "/#/propsmall";
    },
    cancelSms() {
      this.interview = false;
      this.$interview.hidden();
      this.senddxDialogVisible = false;
      window.location.reload();
    },
    ApiSendInterview(){
      let that = this;
      this.$api.sendInterview("post", this.interviewForm).then((res) => {
        if (res.code == 10200) {
          let arr = [];
          arr.push(res.data.id);
          that.sendList = arr;

          that.interview = false;
          that.senddxDialogVisible = true;
          this.$message({
            message: "面试申请已发出",
            type: "success",
          });
          setTimeout(() => {
            window.location.reload();
          }, 300)
        } else {
          that.interview = false;
          this.$message({
            message: res.message,
            type: "error",
          });
        }
        // this.interview = false;
      });
    },
    sendMes() {
      this.$api.sendMessage("post", this.sendList).then((res) => {
        if (res.code == "10901") {
          this.senddxDialogVisible = false;
          this.dxbzDialogVisible = true;
          return;
        }
        this.$message({
          message: "发送成功",
          type: "success",
        });
        this.interview = false;
        this.senddxDialogVisible = false;
        window.location.reload();
      });
    },
  },
};
</script>
<style lang="less" scoped>
.tipInput {
  width: 300px;
}
.amap-wrapper {
  width: 500px;
  height: 250px;
  margin-bottom: 20px;
}
#info-window {
  padding: 0px 20px;
  background: #ffffff;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}
.updown_box {
  line-height: 3;
  padding: 60px 0;
  position: relative;
  .from-box-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .closeicon {
    position: absolute;
    top: -60px;
    right: 0;
    font-size: 26px;
    color: #f3f3f3;
  }
  .ub-img {
    text-align: center;
    img {
      width: 60px;
      height: 60px;
    }
  }
  .ub-text {
    text-align: center;
    color: #111111;
  }
  .btnbox {
    text-align: center;
    /deep/ .el-button {
      border-radius: 0px;
      width: 200px;
      margin-top: 50px;
      background: #00bcff;
      border: none;
    }
    .qx {
      background-color: #ffffff;
      color: #00bcff;
      border: 1px solid #00bcff;
      margin-right: 10px;
    }
  }
}
.work-box-out {
  .closeicon {
    position: absolute;
    top: -40px;
    right: 0;
    font-size: 26px;
    color: #f3f3f3;
  }
  .work-box {
    position: relative;
    .wb-tit {
      padding: 17px 0;
      text-align: center;
      background-color: #f3f3f3;
      color: #000000;
      font-weight: 600;
    }
    /deep/ .el-input__inner {
      border-radius: 0px;
    }
    /deep/ .el-button {
      border-radius: 0px;
    }
    .frombox {
      padding: 50px 60px 30px 60px;
      .demo-ruleForm {
        button {
          width: 160px;
          height: 40px;
          background-color: #00bcff;
        }
        .qux {
          background-color: #fff;
          border: 1px solid #00bcff;
          color: #00bcff;
        }
      }
    }
  }
}
.batch-box-out {
  .closeicon {
    position: absolute;
    top: -40px;
    right: 0;
    font-size: 26px;
    color: #f3f3f3;
  }
  .batch-box {
    position: relative;
    .wb-tit {
      padding: 17px 0;
      text-align: center;
      background-color: #f3f3f3;
      color: #000000;
      font-weight: 600;
    }
    /deep/ .el-input__inner,
    /deep/ .el-textarea__inner {
      border-radius: 0px;
    }
    /deep/ .el-button {
      border-radius: 0px;
    }
    .frombox {
      padding: 50px 80px 50px 50px;
      .demo-ruleForm {
        .btn-next {
          width: 100%;
          height: 40px;
          background-color: #00bcff;
        }
      }
    }
  }
}
</style>
