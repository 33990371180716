import Vue from 'vue'
import videoDetail from './videoDetail.vue'
let VideoDetail =Vue.extend((videoDetail))
let instance
var instal={}
instal.show= function (item){
    instance=new VideoDetail({
      data:{
        show:item.show,
				config:item.data
      }
    })
    instance.$mount()
    if(!document.getElementById('videoDetail')){
      document.body.appendChild(instance.$el)
    }
  }

  instal.hidden=function(){//移除事件
    if(document.getElementById('videoDetail')){
      document.body.removeChild(document.getElementById('videoDetail'))
    }
  }
  export default instal
