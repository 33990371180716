import Vue from 'vue'
import resumeDetail from './resumeDetail.vue'
let ResumeDetail =Vue.extend((resumeDetail))
let instance
var instal={}
instal.show= function (item){
    instance=new ResumeDetail({
      data:{
        show:item.show,
				type:item.type,
				companyJobId:item.companyJobId,
				userJobId:item.userJobId,
				positionoptions:item.positionoptions,
				callback:item.callback
      }
    })
    instance.$mount()
    if(!document.getElementById('resumeDetail')){
      document.body.appendChild(instance.$el)
    }
  }

  instal.hidden=function(){//移除事件
    if(document.getElementById('resumeDetail')){
      document.body.removeChild(document.getElementById('resumeDetail'))
    }
  }
  export default instal
