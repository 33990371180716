<template>
  <div id="app">
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  // /***
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true
    }
  },
  mounted() {
    window.addEventListener('hashchange', (e) => {
      var currentPath = window.location.hash.slice(1); // 获取输入的路由
      console.log("AppConfig hashChange mode log.e = ", e);
      if (this.$router.path !== currentPath) {
        this.$router.push(currentPath); // 动态跳转
      }
    }, false);
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }
  },
  components: {}
   // ***/
}
</script>

<style>
.el-badge__content--danger {
  background-color: #ff0000 !important;
}
.impowerBox .status {
  color: #fff;
}
</style>
