import Vue from "vue";
import jineng from "./jineng.vue";
let Report = Vue.extend(jineng);
let instance;
var instal = {};
instal.show = function(item) {
  instance = new Report({
    data: {
      show: item.show,
    },
  });
  instance.$mount();
  if (!document.getElementById("jinengDialog")) {
    document.body.appendChild(instance.$el);
  }
};

instal.hidden = function() {
  //移除事件
  if (document.getElementById("jinengDialog")) {
    document.body.removeChild(document.getElementById("jinengDialog"));
  }
};
export default instal;
