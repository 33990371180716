import axios from "axios";
import { Message } from "element-ui";
import router from "../router/index.js";

// 设置axios全局默认的BASE-URL， 只要设置了全局的默认base_url，以后的请求会自动拼接上base_url
// -------------------------------注意改成自己的公共url------------------------------------
axios.defaults.baseURL = "https://api.cat-shop.penkuoer.com";
axios.defaults.timeout = 30000;

let loading;

// 配置axios的请求拦截器-(每次在请求头上携带后台分配的token-后台判断token是否有效等问题)
axios.interceptors.request.use(
  (config) => {
    // 在发送请求之前做些什么
    // console.log('请求到了哟', config.headers.Authorization)

    // console.log(config);
    // // 如果有其他的特殊配置 只需要判断config参数 设置即可
    // let showLoading = true;
    // if (config.loading === false) {
    //   showLoading = false;
    // }

    // if (showLoading) {
    //   loading = Loading.service({
    //     text: "加载中...",
    //     spinner: "el-icon-loading",
    //     background: "rgba(0, 0, 0, 0.08)",
    //   });
    // }

    // 标识系统为AJAX请求
    config.headers["X-Requested-With"] = "XMLHttpRequest";

    // 统一的给config设置 token-------------------------------注意获取方法------------------------------------
    // config.headers.Authorization = JSON.parse(localStorage.getItem('token'))
    if (sessionStorage.getItem("token")) {
      config.headers["Token"] = sessionStorage.getItem("token");
    } else {
      config.headers["Token"] = "97aa8f6b569648c78005240033aa0438";
    }
    return config;
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 响应拦截器 与后端定义状态是100时候是错误 跳转到登录界面
axios.interceptors.response.use(
  (response) => {
    // 成功status：200 对响应数据做点什么
    // console.log("接口success", response);
    loading && loading.close();

    // 根据接口返回状态码 定义
    const { code, data, message, count } = response.data;
    if (code) {
      switch (code) {
        case 10200:
          return { code, data, count };
        case 10605:
          return { code, data, message };
        case 10901:
          return { code, data, message };
        case 10801:
          return { code, data, message };
        case 10400:
          return { code, data, message };
        case 10802:
          return { code, data, message };
        case 3012:
          return { code, data, message };
        case 10603:
          return { code, data, message };
        case 404:
          Message({
            message: "网络请求不存在",
            type: "error",
            duration: 2 * 1000,
          });
          return Promise.reject({ code, data });
        case 10600:
          Message({
            message: "登录已失效，请重新登录",
            type: "error",
            duration: 2 * 1000,
          });
          localStorage.removeItem("token");
          router.push({
            path: "/login",
            querry: {}, // 登录过期 回到登录页
          });
          return Promise.reject({ code, data });
        case 10803:
          Message({
            message: "请勿提交违规字符",
            type: "error",
            duration: 2 * 1000,
          });

          return Promise.reject({ code, data });
        // case 4011:
        //   Message.closeAll();
        //   MessageBox.alert("登录超时或身份失效,请重新登录！", "警告", {
        //     customClass: "alertbox",
        //     confirmButtonText: "确定",
        //     type: "warning",
        //     center: true,
        //     callback: action => {
        //       // location.reload()
        //       router.push("/");
        //     }
        //   });
        //   return Promise.reject({ code, data });
        case 4002:
        default:
          Message({
            message: message || "Error",
            type: "error",
            duration: 5 * 1000,
          });
          return Promise.reject({ code, data });
      }
    }
    //   return response.data
  },
  (error) => {
    loading && loading.close();
    console.log("接口error", error, error.response);

    const { status } = error.response;
    switch (status) {
      case 500:
        Message.closeAll();
        Message({
          message: "请求超时",
          type: "error",
          duration: 3 * 1000,
        });
        break;
      case 700:
        Message.closeAll();
        Message({
          message: "网络中断",
          type: "error",
          duration: 3 * 1000,
        });
        break;
      default:
        Message({
          message: error.message + "!",
          type: "error",
          duration: 3 * 1000,
        });
    }
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

const $http = {};

$http.get = function(url, data, config) {
  //  这一步把api方法里的  地址 参数 配置传入进来 配置到config 然后调用上面封装的axios
  config = config || {};
  config.url = url;
  config.method = "get";
  config.params = data;
  return axios.request(config);
};

$http.delete = function(url, data, config) {
  config = config || {};
  config.url = url;
  config.method = "delete";
  config.params = data;
  return axios.request(config);
};

$http.post = function(url, data, config) {
  config = config || {};
  config.url = url;
  config.method = "post";
  config.data = data;
  return axios.request(config);
};

$http.put = function(url, data, config) {
  config = config || {};
  config.url = url;
  config.method = "put";
  config.data = data;
  return axios.request(config);
};

export { axios, $http };
