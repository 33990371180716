import Vue from 'vue'
import report from './report.vue'
let Report =Vue.extend((report))
let instance
var instal={}
instal.show= function (item){
    instance=new Report({
      data:{
        show:item.show,
				config:item.data
      }
    })
    instance.$mount()
    if(!document.getElementById('report')){
      document.body.appendChild(instance.$el)
    }
  }

  instal.hidden=function(){//移除事件
    if(document.getElementById('report')){
      document.body.removeChild(document.getElementById('report'))
    }
  }
  export default instal
